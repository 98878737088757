<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="session" />
		<div class="admin-parking">
			<SessionDetail v-if="selectedSession" :close="closeViewSessionDetails" :session="selectedSession"
				:cancelSession="cancelSession" :onSessionClose="setSessionToClose" />
			<div v-else>
				<div style="" class="tabs">
					<button :class="{ active: filters.isActive }" class="tab" type="button"
						@click="setFilter('isActive', true)">
						Active Sessions
					</button>
					<button :class="{ active: !filters.isActive }" class="tab" type="button"
						@click="setFilter('isActive', false)">
						All Sessions
					</button>
				</div>
				<div class="admin-parking-info-header">
					<h2 class="admin-parking-info-header-title">Select a session</h2>
					<form class="admin-parking-form admin-session-form" data-vv-scope="parking-search-form"
						autocomplete="off" @submit.prevent="searchForm()">
						<div class="admin-parking-search-content">
							<div class="admin-parking-search-field">
								<input ref="search" v-model="query" v-validate="{ required: true, min: 2 }"
									:placeholder="$t('Rechercher session (ID, parking, Nom, e-mail)')"
									:class="{ error: errors.has('parking-search-form.search') }" name="search"
									type="text" class="input-field admin-input-field input admin-search-field" />
								<p v-show="errors.has('parking-search-form.search')" class="input-error">
									{{ errors.first('parking-search-form.search') }}
								</p>
							</div>
							<button :disabled="errors.any('parking-search-form')" hidden
								:class="{ disabled: errors.any('parking-search-form') }"
								class="button blue admin-parking-search-button" type="submit">
								Search
							</button>
						</div>

					</form>
				</div>
				<div class="admin-parking-search">
					<section v-if="filteredSessions.length > 0">
						<div class="admin-item-row admin-parking-item-header" style="border-color: black">
							<div class="admin-item-col small header">ID</div>
							<div class="admin-item-col medium header">Name</div>
							<div class="admin-item-col small header">N. of Bikes</div>
							<div class="admin-item-col small header">Parking</div>
							<div class="admin-item-col small header">Start Time</div>
							<div class="admin-item-col small header">Duration</div>
							<div class="admin-item-col small header">Action</div>
						</div>
						<SessionResult v-for="(p, index) in filteredSessions" :key="index" :session="p"
							:cancelSession="cancelSession" @on-click="viewSessionDetails($event)"
							:onSessionClose="setSessionToClose" />
					</section>
					<div class="paginator">
						<div class="left" @click="() => selectPage('prev')">
							<img src="../../assets/images/icon_arrow_left_dark_grey.svg" alt="Add Circle Icon"
								width="18" :class="{ disabled: selectedIndex == 0 }" />
						</div>
						<div class="page-selector" v-for="(paginationItem, index) in paginationList"
							:key="'pagination-item-' + index"
							:class="{ selected: selectedIndex + 1 == paginationItem.pageNumber }"
							@click="() => selectPage(paginationItem.pageNumber)">
							{{ paginationItem.pageNumber || '...' }}
						</div>
						<div class="right" @click="() => selectPage('next')">
							<img src="../../assets/images/icon_arrow_right_dark_grey.svg" alt="Add Circle Icon"
								width="18" :class="{ disabled: selectedIndex == allSessions.length - 1 }" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<Modal v-if="!!sessionToClose" :modalOpen="!!sessionToClose" :cancelLabel="'Cancel'" :confirmLabel="'Confirm'"
			@confirm="callCloseSession" @cancel="setSessionToClose" @close="setSessionToClose">
			<template>
				<div>
					<h1 class="small-title center-div">
						Are you sure you want to close session {{ sessionToClose.id }}
					</h1>
					<div class="temporaryAccess-modal-parking-info">
						<p class="">
							Please enter amount if you want to charge payment (optional)
						</p>
						<input v-model="sessionToClose.amount" type="number" class="input-field small" placeholder="€0"
							name="amount" />
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import Modal from '@/components/Modal.vue'
import SessionResult from '@/components/admin/session/SessionResult.vue'
import SessionDetail from '@/components/admin/session/SessionDetail.vue'
import ValidationRequired from '@/mixins/validation-required'
import { filter, find } from 'lodash/fp'
import { getPaginationList } from '@/helpers/pagination'

/* import { logger } from '@/logger' */

export default {
	name: 'AdminSession',
	components: {
		Loading,
		Menu,
		SessionResult,
		SessionDetail,
		Modal,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			query: '',
			filters: {
				isActive: true,
			},
			formOpen: false,
			filteredSessions: [],
			customErrors: {},
			hasCustomError: false,
			selectedSession: null,
			selectedIndex: 0,
			paginationList: [],
			perPageItems: 30,
			sessionToClose: null,
		}
	},
	computed: {
		...mapState('admin', {
			allSessions: (state) => state.sessions.all,
			totalSessions: (state) => state.sessions.total,
			loading: (state) => (state.loading || state.sessions.isLoading),
		}),
	},
	mounted() {
		this.$validator.pause()
		this.getSessions({ limit: 50, offset: 0, isActive: true })
	},
	methods: {
		...mapActions('admin', {
			getSessions: 'sessions/getSessions',
			closeSession: 'sessions/closeSession',
		}),
		...mapActions('session', {
			cancelCurrentSession: 'cancelCurrentSession',
		}),
		searchForm() {
			if (!this.query) {
				// this.filteredSessions = [...this.allSessions]
				this.getSessions({ limit: this.perPageItems, offset: 0, ...this.filters })
			} else {
				const re = new RegExp(this.query, 'i')
				this.getSessions({ limit: this.perPageItems, offset: 0, ...this.filters, search: this.query })
				/* this.filteredSessions = filter(
					(session) =>
						session.parking &&
						(session.parking.code.search(re) >= 0 ||
							session.sessionId.search(re) >= 0 ||
							`${session.user.firstName} ${session.user.lastName}`.search(re) >= 0),
					this.allSessions
				) */
			}
		},
		setFilter(key, value) {
			this.filters[key] = value
			this.getSessions({ limit: this.perPageItems, offset: 0, ...this.filters, search: this.query })
			// this.searchForm();		
		},
		cancelSession(sessionId) {
			if (confirm('Are you sure you want to cancel this session IMMEDIATELY?')) {
				return this.cancelNowSession(sessionId)
			}
			return false
		},
		async cancelNowSession(sessionId) {
			try {
				const response = await this.cancelCurrentSession(parseInt(sessionId))
				if (response) {
					this.getSessions({ limit: 50, offset: 0, isActive: true })
				}
			} catch (error) {
				console.error('Error fetching session:', error)
			}
		},
		viewSessionDetails(id) {
			this.selectedSession = find({ id }, this.allSessions)
		},
		closeViewSessionDetails() {
			this.selectedSession = null
		},
		getPaginationList,
		selectPage(page) {
			switch (page) {
				case 'prev':
					this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.selectedIndex
					break
				case 'next':
					this.selectedIndex =
						this.selectedIndex < this.userSessions?.length - 1
							? this.selectedIndex + 1
							: this.selectedIndex
					break
				default:
					this.selectedIndex =
						page && page > 0 && page <= this.totalSessions ? page - 1 : this.selectedIndex
					break
			}
			this.getSessions({ limit: this.perPageItems, offset: (this.perPageItems * (page-1)), ...this.filters })
			this.setPaginationList()
		},
		setPaginationList() {
			const pages = Math.ceil(this.totalSessions / this.perPageItems)
			this.paginationList = this.getPaginationList(pages, this.selectedIndex + 1)
		},
		setSessionToClose(sessionId) {
			if(sessionId) {
				this.sessionToClose = find({ id: sessionId }, this.allSessions)
				console.log('sessionToClose',sessionId, this.allSessions, this.sessionToClose)
				this.sessionToClose = this.sessionToClose? Object.assign({}, this.sessionToClose) : null 
			} else {
				this.sessionToClose = null
			}				
		},
		async callCloseSession() {
			console.log('closing session..', this.sessionToClose.id, this.sessionToClose.amount)
			const response = await this.closeSession({ sessionId: this.sessionToClose.id, amount: this.sessionToClose.amount })
			if (response && response.sessionId) {
				this.sessionToClose = null
				this.getSessions({ limit: this.perPageItems, offset: (this.perPageItems * (page - 1)), ...this.filters, search: this.query })
			}
		}
		
	},
	watch: {
		allSessions(newVal) {
			if (newVal) {
				this.filteredSessions = newVal || []
				if (this.query) {
					// this.searchForm()
				}
				this.setPaginationList()
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminParking';
@import '../../assets/scss/pages/admin';

.mx-datepicker {
	width: 100%;
}

.mx-input-append {
	width: 35px;
	right: 5px;
}

.admin-session-form {
	width: 75%;
}

.admin-parking-search {
	margin-top: 10px;

	.admin-parking-item-header {
		padding-bottom: 8px;
	}
}.tabs {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;

	.tab {
		font-size: 18px;
		font-weight: bold;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		color: dodgerblue;
		background: none;
		border: none;
		border-radius: 0px;
		border-bottom: 2px solid transparent;
		padding: 0 20px;
		cursor: pointer;

		&.active {
			color: dodgerblue;
			border-bottom: 2px solid dodgerblue;
		}
	}

}
.paginator {
	// background: #faf2d5;
	padding-top: 16px;
	display: flex;
	justify-content: center;

	.left {
		display: flex;
		cursor: pointer;
		margin-right: 8px;
	}

	.right {
		display: flex;
		cursor: pointer;
		margin-left: 8px;
	}

	.page-selector {
		cursor: pointer;
		padding: 0 2px;
		margin: 0 6px;

		&.selected {
			color: #e75131;
		}
	}

	.disabled {
		opacity: 0.3;
	}
}
</style>
